import React from 'react';
import { CommonCard, TitleSecondary } from '../../../Utility/Utility';

const SiteStats = ({ siteStats }) => {

  return (
    <CommonCard>
      <div className="mb-5 flex justify-between items-center">
          <TitleSecondary variant="light-blue">
             Global Statistics
          </TitleSecondary>
      </div>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <tbody>
            {siteStats.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={index}>
                    <td className={classes}>
                      {d.metric}
                    </td>
                    <td className='font-bold flex justify-end items-center text-blue-400'>
                      {d.value}
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </table>
      </div>
    </CommonCard>
  );
};

export default SiteStats;