export const PrimaryHeading = ({ children }) => {
  return (
    <h2 className="text-white text-xl lg:text-2xl font-bold">{children}</h2>
  );
};

export const AppTitle = ({ children }) => {
  return (
    <h2 className="text-richBlack font-semibold text-3xl xl:text-[44px] xl:leading-[54px] mb-2 xl:mb-5">
      {children}
    </h2>
  );
};