import React from 'react';
import { useUserAuth } from '../../../Contexts/authContext';
import { useHelpers } from '../../../Hooks/helpers';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

const UserStats = ({ stats, openCauseEdit }) => {
  
  const helper = useHelpers();
  const auth = useUserAuth();

  let tableHead = [
    <>My Statistics</>, 
    <></>
  ];

  return (
  <div className="w-full overflow-scroll px-0 table-custom">
      <table className="w-full">
        <thead>
          <tr>
            {tableHead.map((head, key) => (
              <th key={key} className="">{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Username (ID)</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'>{stats.username} (ID {stats.user_id})</td>
          </tr>
          <tr>
            <td>Wallet Address</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.trimAddress address={stats.user_address} copy /></td>
          </tr>
          <tr>
            <td>Referrer Username (ID)</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'>{stats.referrer_username} (ID {stats.referrer_id})</td>
          </tr>
          <tr>
            <td>Referrer Address</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.trimAddress address={stats.referrer_address} copy /></td>
          </tr>
          <tr>
            <td>Total Claimed</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.AmountToCurrency amount={stats.total_claimed/100} decimals={2} /></td>
          </tr>
          <tr>
            <td>FastStart Earnings</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.AmountToCurrency amount={stats.faststart_earnings} decimals={2} /></td>
          </tr>
          {/* <tr>
            <td>Missed FastStart Earnings</td>
            <td className='font-semibold flex justify-end items-center text-red-400'><helper.AmountToCurrency amount={stats.missed_faststart_earnings} decimals={2} /></td>
          </tr> */}
          <tr>
            <td>Loop 8 Global Earnings</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.AmountToCurrency amount={stats.globalfill_earnings} decimals={2} /></td>
          </tr>
          <tr>
            <td>Unilevel Earnings</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.AmountToCurrency amount={stats.unilevel_earnings} decimals={2} /></td>
          </tr>
          {/* <tr>
            <td>Missed Unilevel Earnings</td>
            <td className='font-semibold flex justify-end items-center text-red-400'><helper.AmountToCurrency amount={stats.missed_unilevel_earnings} decimals={2} /></td>
          </tr> */}
          <tr>
            <td>Checkmatch Earnings</td>
            <td className='font-semibold flex justify-end items-center text-gray-800'><helper.AmountToCurrency amount={stats.checkmatch_earnings} decimals={2} /></td>
          </tr>
          {/* <tr>
            <td>Missed Checkmatch Earnings</td>
            <td className='font-semibold flex justify-end items-center text-red-400'><helper.AmountToCurrency amount={stats.missed_checkmatch_earnings} decimals={2} /></td>
          </tr> */}
        </tbody>
      </table>
      <h3 className='mt-3 font-bold text-md text-purple-600 flex justify-center items-center gap-2'>My Contribution Cause <PencilSquareIcon className='w-4' onClick={() => openCauseEdit(stats.cause)}/></h3>
      <p className='mb-3 text-sm text-gray-600'>{stats.cause}</p>
    </div>
  );
};
export default UserStats;