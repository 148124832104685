import { Button } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import logoSingle from "../../Assets/logo-single.png";
import plus from "../../Assets/plus.png";
import tg from "../../Assets/telegram.png";
import yt from "../../Assets/youtube.png";
import RefLink from "./RefLink";
import { SidebarWithBurgerMenu } from "./SidebarWithBurgerMenu";
import appConfig from "../../Utility/AppConfig";

const Header = () => {

  const navigate = useNavigate();

  return (
    <header className="px-3 xl:px-8 py-3 md:py-5 lg:block items-center justify-between bg-white border-b border-[#DCDCDC]">
      <div className="flex items-center justify-between mb-3 lg:mb-0">
        <div className="flex items-center">
          <Link className="xl:hidden mr-4">
            <img className="w-[64px]" src={logoSingle} alt="" />
          </Link>
          <div className="hidden lg:block">
            <RefLink />
          </div>
        </div>
        <div className="flex items-center justify-end space-x-3 lg:space-x-6 xl:space-x-8">
          
          <div className="hidden lg:flex justify-end gap-2 items-center">
            {/* <Button onClick={() => navigate('/purchase')} className="bg-[#04D88C] inline-flex items-center gap-3 rounded-full text-base font-semibold capitalize shadow-none">
              Contribute Entries <img src={plus} alt="" />
            </Button> */}
            <div className="">
            <a href={appConfig.youtube_complan} target="blank"><img src={yt} className="w-10" /></a>
            </div>
            <div className="">
              <a href={appConfig.telgram} target="blank"><img src={tg} className="w-10 animate-pulse" /></a>
            </div>
          </div>
          <div className="xl:hidden !-mr-2 lg:!ml-3">
            <SidebarWithBurgerMenu />
          </div>
        </div>
      </div>
      <div className="lg:hidden grid grid-cols-1 md:grid-cols-[2fr_1fr] gap-3">
        <div>
          <RefLink />
        </div>
        <div className="flex justify-end gap-2 items-center">
          {/* <Button onClick={() => navigate('/purchase')} className="bg-[#04D88C] flex justify-between md:inline-flex items-center gap-3 rounded-full text-base font-semibold capitalize shadow-none">
            Contribute Entries <img src={plus} alt="" />
          </Button> */}
          <div className="">
            <a href={appConfig.youtube_complan} target="blank"><img src={yt} className="w-10" /></a>
          </div>
          <div className="">
            <a href={appConfig.telgram} target="blank"><img src={tg} className="w-10 animate-pulse" /></a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
