import { Button, Chip } from '@material-tailwind/react';
import React, { useState } from 'react';
import Timestamp from 'react-timestamp';
import useDynamicTable, { DynamicPagination } from '../../../Hooks/dynamicTable';
import { useHelpers } from '../../../Hooks/helpers';
import { CommonCard, ContentLoading, LoadingData, TitleSecondary } from '../../../Utility/Utility';
import { useCallAPI } from '../../../Hooks/callAPI';

const ContestsHistory = () => {

  const helper = useHelpers();
  const callAPI = useCallAPI();

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getDeclaredContests');

  let tableHead = [
    <>Season</>, 
    <>Prize Pool</>,
    <></>
  ];

  const [resultsShowingID, setResultsShowingID] = useState(null);
  const [resultsData, setResultsData] = useState([]);

  const showContestResult = async (id) => {
    try {
      setResultsShowingID(id);
      // Get results from the API for the contest
      const res = await callAPI('user/getContestWinners/' + id);
      setResultsData(res);
    } catch (error) {
      console.error('Error fetching contest results:', error);
      // Handle the error here
    }
  }

  return (
    <CommonCard>
      <div className="mb-5 flex justify-between items-center">
        <TitleSecondary variant="light-orange">
            Contests History
        </TitleSecondary>
      </div>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(data.length > 0 && !loading) && data.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <>
                    <tr key={index}>
                      <td className={classes}>
                        <Timestamp date={d.start} options={{ format: "date" }}/> -<br/><Timestamp date={d.end} options={{ format: "date" }} />
                      </td>
                      <td className={classes}>
                        <helper.AmountToCurrency amount={d.pool} />
                      </td>
                      <td className={classes}>
                        <Button color="blue" size='sm' onClick={() => showContestResult(d.id)} disabled={resultsShowingID*1 === d.id*1}>View Winners</Button>
                      </td>
                    </tr>
                    {resultsShowingID*1 === d.id*1 && (
                      <tr>
                        <td colSpan={tableHead.length}>
                          <table className="w-full">
                            <thead>
                              <tr>
                                <th>Rank</th>
                                <th>Username</th>
                                <th>Prize Won</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resultsData.length > 0 ? resultsData.map((p, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{p.rankWon}</td>
                                    <td>{p.username}</td>
                                    <td className="font-bold text-lg text-red-500"><helper.AmountToCurrency amount={p.amountWon} decimals={0} /></td>
                                  </tr>
                                )
                              })
                              :
                                <tr>
                                  <td colSpan={3}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </>
                );
              },
            )}
            {loading && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
              </tr>
            }
            {((!data || data.length === 0) && !loading) && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </CommonCard>
  );
};

export default ContestsHistory;