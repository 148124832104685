import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useCallAPI } from "./callAPI";
import { usePollAPI } from "./pollAPI";

export const useWaitRePurchase = () => {

    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
        
    const wait = (hash, pendingDialog, appDialog) => {
        
        pendingDialog.open({
            header: 'Processing Contribution..',
            description: 'Please wait while the transaction completes. This should take 0-2 minutes.',
            hash: hash
        });
        
        return new Promise(async (resolve, reject) => {
            try {
                const apiToPoll = () => callAPI("auth/checkTxn/" + hash);
                const pollContinueCondition = (res) => res.status === "pending" || res.status === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                pendingDialog.close();

                if(pollRes.status == "success") {
                    appDialog.open({
                        icon: <CheckBadgeIcon className="w-[40px] text-green-400" />,
                        header: 'Contribution Complete!',
                        description: 'Contribution using repurchase balance completed successfully.',
                        backdropDismiss: true,
                        buttons: [
                            {
                                text: 'Continue',
                                color: 'blue',
                                
                            }
                        ]
                    });
                    return resolve(true);
                } else if(pollRes.status == "failed") {
                    toast.error("Transaction Failed");
                    return reject(false);
                }
            } catch (error) {
                console.log(error.message);
                reject(false);
            }
        });
    }

    return { wait };

};