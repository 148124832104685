import { Button } from '@material-tailwind/react';
import { ping } from 'ldrs';
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { useUserAuth } from '../Contexts/authContext';
import { useCallAPI } from './callAPI';
import { useHelpers } from './helpers';
import { usePollAPI } from './pollAPI';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';

export const usePendingDeposit = () => {

    const callAPI = useCallAPI();
    const auth = useUserAuth();
    const pollResults = usePollAPI();

    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [hash, setHash] = useState('');

    const open = async ({ orderId, successDesc }) => {
        return new Promise(async (resolve, reject) => {
            try {
                setShow(true);
                setHash(orderId);
                await successCallback(orderId, successDesc);
                return resolve();
            } catch (error) {
                console.log(error);
                return reject(error);
            }
        });
    }

    const close = () => {
        setShow(false);
    }

    const closeSuccess = () => {
        setShowSuccess(false);
    }

    const successCallback = (hash, successDesc) => {
        return new Promise(async (resolve, reject) => {
            try {
                const apiToPoll = () => callAPI("user/checkTopupTxn/" + hash);
                const pollContinueCondition = (res) => res.status === "pending" || res.status === "received" || res.status === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                close();
                if(pollRes.status == "completed") {
                    setShowSuccess(successDesc);
                    await auth.bootApp();
                    return resolve();
                } else {
                    return reject('Topup failed with status : ' + pollRes.status);
                }
            } catch (error) {
                return reject('Topup failed with error : ' + error);
            }
            
        });
    }

    return { show, showSuccess, hash, open, close, closeSuccess };
}

export const PendingDepositModal = ({ hook }) => {

    const helpers = useHelpers();
    
    ping.register();

    return (
        <>
            <BottomSheet open={hook.show} className='relative z-[100]'>
                <div className='items-center text-center py-10 px-10'>
                    <l-ping
                        size="70"
                        speed="2" 
                        color="gray" 
                    />
                    <h1 className="text-xl sm:text-3xl text-green-500 mb-3 mt-7 font-semibold">Processing Topup. Please wait...</h1>
                    <p className="text-md sm:text-lg text-[#777] max-w-[390px] mx-auto font-ibm-plex-sans font-normal">
                        Topup will be processed in 1-2 mins.
                    </p>
                </div>
            </BottomSheet>
            <BottomSheet open={hook.showSuccess} className='relative z-[100]'>
                <div className='text-center py-10 px-10'>
                    <CheckBadgeIcon className="w-[120px] text-green-400 mx-auto" />
                    <h1 className="text-xl sm:text-3xl text-green-500 mb-3 mt-7 font-semibold">Topup Successful!</h1>
                    <p className="text-md sm:text-lg text-[#777] max-w-[390px] mx-auto font-ibm-plex-sans font-normal">
                        {hook.showSuccess}
                    </p>
                    <Button color="blue" variant="gradient" onClick={hook.closeSuccess} className="mt-5">Close</Button>
                </div>
            </BottomSheet>
        </>
    )
}