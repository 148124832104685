import logo from '../Assets/logo-single.png';

export default function LayoutLoading() {  
    return (
        <div className="grid h-screen place-items-center bg-primaryGradient_to_right">
            <div className="text-center">
                <img src={logo} alt="logo" className="animate-bounce mx-auto mb-4" />
                <h3 className="text-light">Loading. Please wait..</h3>
            </div>
        </div>
    );
}