import { Button, Chip } from "@material-tailwind/react";
import { DollarSign } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import Timestamp from "react-timestamp";
import { CommonCard } from "../../../Utility/Utility";
import EntryBoxStats from "./EntryBoxStats";
import { useUserAuth } from "../../../Contexts/authContext";

const EntryBox = ({ entry }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useUserAuth();

  return (
    <CommonCard>
      <div className="">
        <div className="flex justify-between">
          <div className="text-left mb-5">
            <h4 className="font-bold text-2xl text-blue-400">{entry.entry_id}</h4>
            <p className="text-xs text-gray-700">Entry #</p>
          </div>
          {entry.entry_id*1 < auth?.appData.entryToFill*1 && <div><Chip color="red" value='Looped'/></div>}
        </div>
        
        <div className="space-y-6 mb-6">
          <EntryBoxStats title='Total Payments to Claim wallet' value={'$' + entry.total_payments_to_claim} Icon={<DollarSign />} />
          <EntryBoxStats title='Total Payments to Recontribution wallet' value={'$' + entry.total_payments_to_recontr} Icon={<DollarSign />} />
        </div>
        {location.pathname.includes('entries') && <Button color="blue" variant="gradient" onClick={() => navigate('/entry/' + entry.entry_id)}>View Entry</Button>}
        <p className="text-xs mt-3 text-gray-800">Active since <Timestamp date={entry.created} /></p>
      </div>
    </CommonCard>
  );
  
};
export default EntryBox;