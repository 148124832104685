import { useEffect, useState } from "react";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { CommonCard, LoadingData, TitlePrimary } from "../../Utility/Utility";
import { Button } from "@material-tailwind/react";
import { CheckBadgeIcon, CheckCircleIcon } from "@heroicons/react/24/solid";

const Products = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();

  const [products, setProducts] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      getResults();
    }
  }, [auth.loggedIn]);

  const getResults = async () => {
    try {
        const res = await callAPI('user/getProducts');
        setProducts(res);
    } catch (error) {
        console.log(error);
    }
  }

  const openLink = async (id) => {
    try {
      setLoading(true);
      const res = await callAPI('user/openProduct', {productId: id});
      window.open(res.link, '_self');
      res.newUnlock && auth.bootApp();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="mb-5 flex justify-between items-center">
        <TitlePrimary>Products</TitlePrimary>
        <div className="text-right">
          <p className="text-sm text-gray-700">Available Points</p>
          <div className="font-bold leading-7 text-2xl text-blue-400">{auth.appData?.user?.points_balance}</div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        {products ? 
            products.map((p, i) => {
                return (
                    <CommonCard className="" key={i}>
                      <div className="">
                        <div className="mb-5 flex justify-center"><img src={p.thumbnail} className="max-w-[300px]" /></div>
                        <h4 className="font-bold text-xl mb-4">{p.name}</h4>
                        {(p.description.split(',')).map((e,k) => {
                            return (
                                <p className="flex justify-start gap-1 my-2"><CheckBadgeIcon className="w-5 text-blue-600" /> {e}</p>
                            )
                        })}
                        <div className="mt-4">
                          <Button disabled={loading} loading={loading} color="amber" variant="gradient" onClick={() => openLink(p.id)}>Unlock Product</Button>
                        </div>
                      </div>
                    </CommonCard>
                )
                
            }) 
        :
            <LoadingData />
        }
      </div>
    </>
  );
};
export default Products;