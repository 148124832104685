import React from 'react';
import { useUserAuth } from '../../../Contexts/authContext';
import { useHelpers } from '../../../Hooks/helpers';

const BalanceBox = () => {

    const auth = useUserAuth();
    const helper = useHelpers();

    return (
        <div className='bg-green-400 p-4 rounded-10 mb-4 flex justify-between items-center shadow-lg'>
            <p className='text-white leading-4 font-semibold text-sm'>Available<br/>Re-Contribution Balance</p>
            <h4 className='font-bold text-2xl text-white'><helper.AmountToCurrency amount={auth?.appData.user.repurchaseBalance} /></h4>
        </div>
    )
}
export default BalanceBox;