import { Dialog, DialogBody } from '@material-tailwind/react';
import UpdateCause from '../layout/UpdateCause';

export const InputCauseDialog = ({ hook }) => {

    return (
        <Dialog size='sm' open={hook.isOpen} dismiss={hook.close} handler={null}>
            <DialogBody className='p-7 text-center'>
                <UpdateCause cause={hook.data} hook={hook} />
            </DialogBody>
        </Dialog>
    )
}