import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import { bsc, bscTestnet, goerli, mainnet, polygon, polygonMumbai } from 'viem/chains';
import { WagmiProvider } from 'wagmi';
import ScrollToTop from './Contexts/ScrollToTop';
import { AppStateProvider } from './Contexts/appStateContext';
import { UserAuthProvider } from './Contexts/authContext';
import Layout from './Routers/Layout';
import appConfig from './Utility/AppConfig';
import Translator from './Components/layout/Translator';

function App() {
  const queryClient = new QueryClient();
  const projectId = appConfig.walletConnectKey;

  const metadata = {
    name: appConfig.appName,
    description: appConfig.appDescription,
    url: appConfig.walletConnectAllowEndpoint,
    icons: ['https://i.ibb.co/CQKmnCK/logo-single.png']
  };

  const chains = [ mainnet, bsc, polygon, goerli, bscTestnet, polygonMumbai ];

  const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true, // Optional - true by default
    // enableEmail: true
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeMode: 'light',
    themeVariables: {
      "--w3m-accent": '#7DCCFF'
    }
  });

  return (
    <AppStateProvider>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <UserAuthProvider>
            <Router>
              <ScrollToTop />
              <Layout />
            </Router>
            <Toaster position="bottom-center" />
          </UserAuthProvider>
        </QueryClientProvider>
      </WagmiProvider>
      {/* <Translator /> */}
    </AppStateProvider>
  );
}
export default App;
