import { Button } from "@material-tailwind/react";
import React, { useEffect } from 'react';
import toast from "react-hot-toast";
import { useNavigate, useParams } from 'react-router-dom';
import IconLogin from "../../Assets/login.png";
import IconRegister from "../../Assets/register.png";
import { AppTitle } from '../../Utility/Headings';

const Splash = () => {

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
      if(params.ref) {
        localStorage.setItem("referrerUsername", params.ref);
        toast.success("Referred by " + params.ref);
      } else {
        if(localStorage.getItem("referrerUsername") !== null && localStorage.getItem("referrerUsername") !== undefined) {
            toast.success("Referred by " + localStorage.getItem("referrerUsername"));
        }
      }
    }, []);
    
    return (
      <div className="space-y-6 xl:space-y-14">
        <div className="border-l-0 border-r-0 border-[#E5E5E5] pb-10 xl:pb-14">
          <AppTitle>
            <span className="inline-block highlight-title">
              Empower
            </span>{" "}
            Your Experience
          </AppTitle>
          <p className="text-gray text-lg leading-snug">
            Unlock the full potential of NewUnity by logging in or{" "}
            <br className="hidden md:block" />
            registering below.
          </p>
          <div className="mt-6 flex items-center space-x-5">
            <Button
              onClick={() => navigate('/login')}
              variant="gradient"
              className="bg-[#04D88C] flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
            >
              Log in <img className="w-6 h-6" src={IconLogin} alt="i" />
            </Button>
            <Button
              onClick={() => navigate('/register')}
              variant="gradient"
              className="bg-primaryGradient_to_right flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
            >
              Register{" "}
              <img className="w-6 h-6" src={IconRegister} alt="i" />
            </Button>
          </div>
        </div>
      </div>
    );
};

export default Splash;
