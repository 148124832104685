import toast from "react-hot-toast";
import { useCallAPI } from "./callAPI";
import { usePollAPI } from "./pollAPI";
import { useUserAuth } from "../Contexts/authContext";
import { useHelpers } from "./helpers";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";

export const useWaitRegister = () => {

    const callAPI = useCallAPI();
    const pollResults = usePollAPI();
    const auth = useUserAuth();
    const helper = useHelpers();
        
    const wait = (hash, pendingDialog, appDialog) => {
        
        pendingDialog.open({
            header: 'Registering wallet..',
            description: 'Please wait while the registration completes. This should take 0-2 minutes.',
            hash: hash
        });
        
        return new Promise(async (resolve, reject) => {
            try {
                const apiToPoll = () => callAPI("auth/checkTxn/" + hash);
                const pollContinueCondition = (res) => res.status === "pending" || res.status === "processing";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                pendingDialog.close();
                if(pollRes.status == "success") {
                    appDialog.open({
                        icon: <CheckBadgeIcon className="w-[40px] text-green-400" />,
                        header: 'Registration Completed!',
                        description: 'Click continue to enter into dashboard',
                        backdropDismiss: true,
                        buttons: [
                            {
                                text: 'Proceed to Login',
                                color: 'blue',
                                action: () => resolve()
                            }
                        ]
                    });
                } else if(pollRes.status == "failed") {
                    toast.error("Transaction Failed");
                    return reject(false);
                }
            } catch (error) {
                console.log(error.message);
                reject(false);
            }
        });
    }

    return { wait };

};