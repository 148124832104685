const EntryBoxStats = ({ title, value, Icon }) => {

  return (
    <div className="flex items-center gap-4">
      <div className="border-gray-400 border-2 rounded-10 p-2 text-gray-400">{Icon}</div>
      <div className="">
        <h4 className="text-lg sm:text-2xl font-bold text-green-400">{value}</h4>
        <p className="text-xs text-gray-700">{title}</p>
      </div>
    </div>
  );
  
};
export default EntryBoxStats;