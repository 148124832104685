import React from "react";
import appConfig from "../../Utility/AppConfig";
import { ButtonGray } from "../../Utility/Buttons";

const Footer = () => {
  return (
    <div className='flex justify-end mr-3 mt-4 text-xs text-white'>
        Copyright &copy; {appConfig.appName} | v{appConfig.version}
    </div>
  );
};

export default Footer;
