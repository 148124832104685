import React from "react";
import AppRouter from "../../Routers/AppRouter";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const DashboardLayout = () => {
  return (
    <div className="bg-primaryGradient_to_right p-3 xl:p-6  min-h-screen">
        <div className="bg-richBlack rounded-3xl xl:rounded-[50px] p-3 xl:p-6 xl:grid grid-cols-[290px_auto]">
          <div className="hidden xl:block">
            <Sidebar />
          </div>
          <div className="bg-[#F4F4F4] rounded-3xl xl:rounded-[30px] overflow-hidden">
            <Header />
            <div className="px-3 xl:px-8 py-4 xl:py-6">
              <AppRouter />
            </div>
          </div>
        </div>
        <Footer />
      </div>
  );
};

export default DashboardLayout;
