import { useEffect, useState } from "react";
import PurchaseBlock from "../../Components/dash/purchase/PurchaseBlock";
import PurchaseHistory from "../../Components/dash/purchase/PurchaseHistory";
import { useUserAuth } from "../../Contexts/authContext";

const Purchase = () => {

  const auth = useUserAuth();

  const [historyKey, setHistoryKey] = useState(1);

  useEffect(() => {
    setHistoryKey(historyKey+1);
  }, [auth]);

  return (
    <>
      <PurchaseBlock />
      <PurchaseHistory key={historyKey} />
    </>
  );
};
export default Purchase;