import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ContentLoading, TitleSecondary } from "../../../Utility/Utility";

const StatisticsChart = ({ data }) => {

  const [graph1, setGraph1] = useState(false); //payments received
  useEffect(() => {
    setGraph1({
      type: "line",
      height: 320,
      series: [
        {
          name: "Checkmatch",
          data: data.checkmatch.sums,
        },
        {
          name: "FastStart",
          data: data.fastStart.sums,
        },
        {
          name: "Loop 8 Global",
          data: data.globalFill.sums,
        },
        {
          name: "Unilevel",
          data: data.unilevel.sums,
        }
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        title: {
          show: "Earnings",
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          "#e07148", // OrangeRed
          "#5aa65a", // Green
          "#5656fc", // Blue
          "#ffe452", // Gold
          "#800080", // Purple
          "#000080" // Navy
         ],
        stroke: {
          lineCap: "round",
          curve: "smooth",
        },
        markers: {
          size: 3,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: "#999",
              fontSize: "10px",
              fontFamily: "inherit",
            },
          },
          categories: data.adminFee.days,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return '$' + value;
            },
            style: {
              colors: "#999",
              fontSize: "9px",
              fontFamily: "inherit",
              fontWeight: 400,
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#cccccc",
          strokeDashArray: 0,
          padding: {
            top: 0,
            right: 0,
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          theme: "light",
        },
        legend: {
          show: false,
          labels: {
            colors: '#444'
          }
        }
      },
    });
  }, []);
  
  return (
    <div className="chart">
      <TitleSecondary>Payments Received</TitleSecondary>
      {graph1 ? 
        <Chart {...graph1} />
        :
        <ContentLoading className='flex justify-center align-middle mb-10 mt-10' />
      }
    </div>
  );
};

export default StatisticsChart;
