import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useCallAPI } from "../Hooks/callAPI";

const UserAuth = createContext();

// context consumer hook
const useUserAuth = () => {
    // get the context
    const context = useContext(UserAuth);

    // if `undefined`, throw an error
    if (context === undefined) {
        throw new Error("UserAuth was used outside of its Provider");
    }

    return context;
};

const UserAuthProvider = ({ children }) => {

    //Declare state vars
    const [appBooted, setAppBooted] = useState(false);
    const [appData, setAppData] = useState({});
    const [userData, setUserData] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState('');

    const callAPI = useCallAPI();

    useEffect(function() {
        bootApp();
    }, []);

    const bootApp = async () => {
        try {
            const res = await callAPI("auth/boot");
            if(res.loggedIn) { //Loggedin
                setAppData(res.appData);            
                setLoggedIn(res.loggedIn);
                setUserId(res.userId);
                setUserData(res.userData);
            }
            setAppBooted(true);
        } catch (error) {
            console.log(error);
        }
    }
    
    // memoize the full context value
    const contextValue = useMemo(() => ({
        appBooted,
        appData,
        userData,
        loggedIn,
        userId,
        bootApp,
    }), [appBooted, appData, userData, loggedIn, userId, bootApp])

    return (
        <UserAuth.Provider value={ contextValue }>
            {children}
        </UserAuth.Provider>
    );
};

export { UserAuthProvider, useUserAuth };