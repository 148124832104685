import { Button, IconButton, Tooltip } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-feather';
import Timestamp from 'react-timestamp';
import useDynamicTable from '../../Hooks/dynamicTable';
import { useHelpers } from '../../Hooks/helpers';
import appConfig from '../../Utility/AppConfig';
import { CommonCard, ContentLoading, EmptyRecords, TitlePrimary, TitleSecondary } from '../../Utility/Utility';
import EntryBox from '../../Components/dash/entries/EntryBox';
import { useParams } from 'react-router-dom';
import { useCallAPI } from '../../Hooks/callAPI';
import { useUserAuth } from '../../Contexts/authContext';
import toast from 'react-hot-toast';
import EntryDownlines from '../../Components/dash/entries/EntryDownlines';
import EntryPayments from '../../Components/dash/entries/EntryPayments';

const Entry = () => {

  const helper = useHelpers();
  const params = useParams();
  const callAPI = useCallAPI();
  const auth = useUserAuth();

  const [entry, setEntry] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      getEntryData();
    }
  }, [auth.loggedIn]);

  const getEntryData = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/getEntry/' + params.id);

      if(!res.entryData) {
        toast.error('Entry not found');
      }

      setEntry(res.entryData);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <TitlePrimary>Viewing entry #{params.id}</TitlePrimary>
      {loading ?
        <div className='flex justify-center pt-3'><ContentLoading /></div>
      :
        <div className="grid grid-cols-1 md:grid-cols-[20%_80%] mt-5 gap-5">
          {entry && entry.entry_id && <div><EntryBox entry={entry} /></div>}
          <div><EntryPayments id={params.id} /></div>
        </div>
      }
      {((!entry || entry.length === 0) && !loading) && 
        <EmptyRecords title='No entries found'  />
      }
    </>
  );
};
export default Entry;