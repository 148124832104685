import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import purchaseBg from '../../../Assets/claim.jpg';
import IconLogin from "../../../Assets/plus.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { useWaitWithdrawal } from "../../../Hooks/waitWithdrawal";
import appConfig from "../../../Utility/AppConfig";
import { ContentLoading, TitlePrimary } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingDex3Dialog } from "../../modals/ProcessingDex3Dialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";

const WithdrawBlock = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();
  const waitWithdrawal = useWaitWithdrawal();
  const appDialog = useAppDialog();
  const pendingTxDialog = useAppDialog();
  const waitingClaimDialog = useAppDialog();
  const params = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      checkWithdrawCallback();
    }
  }, [auth.loggedIn]);

  const checkWithdrawCallback = async () => {
    try {
      // Check here if any params is present
      if(params.orderid) {
        if(params.status === 'success') {
              
          //Get payout link
          const payoutid = await callAPI('user/getpayoutbyid/' + params.orderid);
          await waitWithdrawal.waitClaim(params.orderid, () => window.open(appConfig.payoutLink +  payoutid, '_self'), waitingClaimDialog, appDialog);
          await auth.bootApp();
          
        } else if(params.status === 'cancel') {
          toast.error('Donation Claim Cancelled by user.');
        }
      }

    } catch (error) {
      console.log(error);
    }
  }

  const withdraw = async () => {
    try {

      if(auth?.appData?.user.balance*1 <= 0) return toast.error('Nothing to claim');

      setLoading(true);
      const res = await callAPI('user/createWithdrawal');

      //Wait for transaction
      await waitWithdrawal.waitProcess(res.orderId, pendingTxDialog);
      
      //Get payment link
      const payoutid = await callAPI('user/getpayoutbyid/' + res.orderId);
      window.open(appConfig.payoutLink +  payoutid, '_self');

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="mb-5">
        <TitlePrimary>Claim Donations</TitlePrimary>
      </div>
      <div className="rounded-lg mb-6 flex justify-center p-8" style={{ backgroundImage: `url(${purchaseBg}`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        {auth?.appData?.user?.balance ?
          <div className="bg-[#FCFCFC]/95 rounded-2xl px-4 py-5 md:p-6 shadow-md max-w-[500px] w-full text-center">
            <div className="flex justify-center gap-8">
              <div className="mt-5">
                <h4 className="text-4xl font-bold text-blue-400"><helper.AmountToCurrency amount={auth?.appData.user.balance} decimals={2} /></h4>
                <p>Available donations to claim</p>
              </div>
            </div>
            <div className="flex justify-center mb-3 mt-8">
              <Button
                disabled={auth?.appData.user.balance*1 <= 0 || loading}
                loading={loading}
                onClick={withdraw}
                variant="gradient"
                className="bg-[#04D88C] flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
              >
                Claim Now <img className="w-6 h-6" src={IconLogin} alt="i" />
              </Button>
            </div>
            <div className="leading-[17px] text-sm border-dashed border-2 border-l-0 border-b-0 border-r-0 border-[#E5E5E5] pt-5 mt-8">
            10% of all the donations claimed will be sent to a re-contribution wallet which you can use to contribute new entries every time the wallet collects $40.
            </div>
          </div>
        :
          <ContentLoading />
        }
      </div>
      <ProcessingTxnDialog hook={pendingTxDialog} />
      <ProcessingDex3Dialog hook={waitingClaimDialog} />
      <AppDialog hook={appDialog} />
    </>
  );
};
export default WithdrawBlock;