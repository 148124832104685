import { useEffect, useState } from "react";
import TopupBlock from "../../Components/dash/topup/TopupBlock";
import TopupHistory from "../../Components/dash/topup/TopupHistory";
import { useUserAuth } from "../../Contexts/authContext";

const Topup = () => {

  const auth = useUserAuth();

  const [historyKey, setHistoryKey] = useState(1);

  useEffect(() => {
    setHistoryKey(historyKey+1);
  }, [auth]);

  return (
    <>
      <TopupBlock />
      <TopupHistory key={historyKey} />
    </>
  );
};
export default Topup;