import { Button, Chip, Dialog, DialogBody } from '@material-tailwind/react';
import { PuffLoader } from 'react-spinners';
import { useHelpers } from '../../Hooks/helpers';
import appConfig from '../../Utility/AppConfig';

export const ProcessingDex3Dialog = ({ hook }) => {

    const helper = useHelpers();

    return (
        <Dialog size='sm' open={hook.isOpen} dismiss={hook.close} handler={hook.data.backdropDismiss ? hook.close : null}>
            <DialogBody className='p-7 text-center'>
                <PuffLoader
                    color='#52B86E'
                    loading={true}
                    size={40}
                    aria-label="Loading"
                    data-testid="loader"
                    className='mx-auto'
                />
                <h4 className="text-xl font-bold leading-6 mt-4 text-gray-900">{hook.data.header}</h4>
                <p className="text-sm mt-2 font-normal text-gray-600">{hook.data.description}</p>
                <p className='text-gray-800 my-3 text-sm border-dashed border-2 border-b-0 border-l-0 border-r-0 pt-5 border-[#E5E5E5]'>Didn't complete the {hook.data.linkType}? Click the button below to try again.</p>
                <Button size='sm' onClick={hook.data.openLink} variant='outlined' color='green'>Open {hook.data.linkType} Link</Button>
                <p className='text-gray-800 mt-3 text-sm flex justify-center'>ID : <helper.CopyText value={hook.data.order_id} /></p>
            </DialogBody>
        </Dialog>
    )
}