import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from 'react';
import { useAccount, useSwitchAccount, useWalletClient } from 'wagmi';
import IconLogin from "../../Assets/login.png";
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useDeviceData } from '../../Hooks/deviceData';
import { useWrite } from '../../Hooks/writeContract';
import { AppTitle } from '../../Utility/Headings';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const Login = () => {

    const callAPI = useCallAPI();
    const auth = useUserAuth();
    const account = useAccount();
    const writeContract = useWrite();
    const device = useDeviceData();
    const params = useParams();
    const wallet = useWalletClient();
    
    const [connectAddr, setConnectAddr] = useState(account.address);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setConnectAddr(account.address);
    }, [account.address]);

    useEffect(() => {
      if(wallet?.data?.account?.address && auth.loggedIn === false && params.login === 'yes') {
        loginNow();
      }
    }, [auth.loggedIn, wallet?.data?.account?.address]);

    const loginNow = async () => {
      try {

        setLoading(true);
        const res = await callAPI("auth/getloginnonce", { address: connectAddr });
        const signature = await writeContract.SignMessage(res.nonce, connectAddr);
        
        const pres = await callAPI("auth/completeLogin", { hash: signature, address: connectAddr });
        device.setToken(pres.token);
        await auth.bootApp();
        setLoading(false);

      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }

    return (
      <div className="space-y-6 xl:space-y-14">
        <div className="border-dashed border-2 border-t-0 border-l-0 border-r-0 border-[#E5E5E5] pb-10 xl:pb-14">
          {!account.isConnected ?
            <AppTitle>
              <span className="inline-block highlight-title">1. Connect</span>&nbsp; your wallet
            </AppTitle>
          :
            <AppTitle>
              <span className="inline-block highlight-title">1. Wallet</span>&nbsp;Connected
            </AppTitle>
          }
          <div className="mt-5 xl:mt-8">
            <w3m-button />
            <p className='text-xs mt-2 text-gray-700'>Click ☝️ to show wallet details</p>
          </div>
        </div>
        <div>
          <AppTitle>
            <span className="inline-block highlight-title">
              2. Sign
            </span>&nbsp;Wallet
          </AppTitle>
          <p className="text-gray text-lg leading-snug">
            Sign a nonce request from your wallet to authenticate. <br/>You will not spend any BNB to login.
          </p>
          <div className="mt-6 flex items-center space-x-5">
            <Button
              loading={loading}
              disabled={account.isConnected !== true || loading}
              onClick={loginNow}
              variant="gradient"
              className="bg-[#04D88C] flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
            >
              Log in <img className="w-6 h-6" src={IconLogin} alt="i" />
            </Button>
          </div>
        </div>
      </div>
    );
};

export default Login;
