import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useUserAuth } from "../../Contexts/authContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { CommonCard, LoadingData, TitlePrimary } from "../../Utility/Utility";
import ContestsHistory from "../../Components/dash/bonuspool/ContestsHistory";

const BonusPool = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();

  const [contestData, setContestData] = useState(false);

  useEffect(() => {
    if(auth.loggedIn) {
      getResults();
    }
  }, [auth.loggedIn]);

  const getResults = async () => {
    try {
        const res = await callAPI('user/getOngoingContest');
        setContestData(res);
    } catch (error) {
        console.log(error);
    }
  }

  return (
    <>
      {contestData && contestData.contest ?
        <>
          <div className="mb-5 flex justify-between items-center">
            <TitlePrimary>Bonus Pool <span className="text-lg text-blue-400">Contest #{contestData.contest.id}</span><br/><small className="text-lg text-gray-700">Prize Pool <b className="text-green-400"><helper.AmountToCurrency amount={contestData.totalPool} /></b></small></TitlePrimary>
            <div className="text-right">
              <p className="text-sm text-gray-700">Contest ends in</p>
              <div className="font-bold leading-7 text-2xl text-blue-400"><Countdown date={contestData.contest.end*1000} /></div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            <div>
              <CommonCard>
                <div className="w-full overflow-scroll px-0 table-custom">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th>Rank</th>
                        <th>Username</th>
                        <th>Referrals</th>
                        <th>Prize Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contestData.contestRanking.length > 0 && contestData.contestRanking.map((p, i) => {
                          return (
                            <tr key={i}>
                              <td>{i+1}</td>
                              <td>{p.username}</td>
                              <td>{p.activeRefs}</td>
                              <td className="font-bold !text-lg text-green-400"><helper.AmountToCurrency amount={p.amount} decimals={0} /> <span className="text-gray-600 text-xs">({p.percent}%)</span></td>
                            </tr>
                          )
                      })}
                    </tbody>
                  </table>
                </div>
              </CommonCard>
            </div>
            <div className="">
              <ContestsHistory />
            </div>
          </div>
        </>
      :
        <LoadingData />
      }
    </>
  );
};
export default BonusPool;