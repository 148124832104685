import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast";
import * as Yup from 'yup';
import { useCallAPI } from "../../Hooks/callAPI";
import { FormInput } from "../../Utility/Inputs";
import { useUserAuth } from '../../Contexts/authContext';

const UpdateCause = ({ cause, hook }) => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});

  const defaultValues = {
      cause: cause
  }

  const validationSchema = Yup.object().shape({
      cause: Yup.string()
          .required('Please enter a cause')
          .min(10, "Please enter atleast 10 characters")
          .max(500, "Please keep it short to 500 characters"),
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ defaultValues: defaultValues, mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {
    try {

      setLoading(true);
      setFormError({});

      const formData = getValues();
      await callAPI("user/updateCause", formData);
      await auth.bootApp();
      setLoading(false);
      hook.close();
      toast.success('Cause updated successfully');

    } catch (error) {
      if(error.code && error.code === 403) { //Handle form error
          setFormError(error.errData);
      }
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className='font-bold text-purple-600 text-xl'>One last step to get started.</h3>
      <p className='text-gray-800 text-sm mb-5'>Enter your crowdfunding contribution cause below. Why do you want to raise donations using NewUnity?</p>
      <FormInput name='cause' label="Enter contribution Cause" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
      <div className='flex justify-center'>
        <Button type="submit" loading={loading} disabled={!isValid || !isDirty || loading} className="bg-[#04D88C] mt-3">Update Now</Button>
      </div>
    </form>
  );
};

export default UpdateCause;