import { Chip } from '@material-tailwind/react';
import React from 'react';
import Timestamp from 'react-timestamp';
import useDynamicTable, { DynamicPagination } from '../../../Hooks/dynamicTable';
import { useHelpers } from '../../../Hooks/helpers';
import { CommonCard, ContentLoading, TitleSecondary } from '../../../Utility/Utility';

const EntryPayments = ({ id }) => {

  const helper = useHelpers();

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getEntryPayments/' + id);

  let tableHead = [
    <>From User #<br/>User Addr.</>, 
    <>From Entry #</>,
    <>Type</>,
    <>Amount</>,
    <>Hash</>,
    <>Date</>
  ];

  return (
    <CommonCard>
      <div className="mb-5 flex justify-between items-center">
        <TitleSecondary variant="light-orange">
            Entry Payments
        </TitleSecondary>
      </div>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(data.length > 0 && !loading) && data.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={index}>
                    <td className={classes}>
                      {d.from_id}<br/><helper.trimAddress address={d.address} />
                    </td>
                    <td className={classes}>
                      {d.from_entry}
                    </td>
                    <td className={classes}>
                      <span className='bg-amber-500 px-3 py-2 text-xs text-darker rounded-10 font-bold'><helper.CamelCaseToWords text={d.type} /></span>
                    </td>
                    <td className={classes}>
                      <helper.AmountToCurrency amount={d.amount} />
                    </td>
                    <td className={classes}>
                      <helper.trimAddress address={d.hash} copy/>
                    </td>
                    <td className={classes}>
                      <Timestamp date={d.created} /><br/>
                      <Timestamp date={d.created} relative className='opacity-60' />
                    </td>
                  </tr>
                );
              },
            )}
            {loading && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
              </tr>
            }
            {((!data || data.length === 0) && !loading) && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </CommonCard>
  );
};

export default EntryPayments;