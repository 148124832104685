import { Dialog, DialogBody } from '@material-tailwind/react';
import RecontributeEntries from '../dash/dashboard/RecontributeEntries';

export const RecontributeDialog = ({ hook }) => {

    return (
        <Dialog size='sm' open={hook.isOpen} dismiss={hook.close} handler={null}>
            <DialogBody className='p-7 text-center'>
                <h4 className='font-bold text-lg text-blue-400'>Recontribute Entries to proceed</h4>
                <RecontributeEntries showPopup={hook} />
            </DialogBody>
        </Dialog>
    )
}