import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useUserAuth } from "../../../Contexts/authContext";
import appConfig from "../../../Utility/AppConfig";

const Anouncements = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();

    const [data, setData] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(auth.loggedIn) {
        getAnouncement();
        }
    }, [auth.loggedIn]);

    const getAnouncement = async () => {
        try {
            const res = await callAPI('user/getAnouncement');
            setData(res);

            //Show anouncement only when ther is data available and the user has not seen it before
            if(res && localStorage.getItem('anouncement')*1 !== res.id*1) {
                setShow(true);
            } else {
                setShow(false);
            }
            
        } catch (error) {
        console.log(error);
        }
    }
    
    //Save in the user localstorage that they have seen this anouncement when they click the ok hide button
    //This will prevent the anouncement from showing again
    const hideAnouncement = async () => {
        localStorage.setItem('anouncement', data.id);
        await getAnouncement();
    }
    
    return (
        <>
            {show && data &&
                <div className="">
                    <div dangerouslySetInnerHTML={{ __html: data.anouncement }}></div>
                    <Button color="green" onClick={hideAnouncement} className="bg-green-500 my-5">ok, Hide Announcement</Button>
                </div>
            }
        </>
    );
};
export default Anouncements;
