import { IconButton } from '@material-tailwind/react';
import React from 'react';
import EntryBox from '../../Components/dash/entries/EntryBox';
import useDynamicTable, { DynamicPagination } from '../../Hooks/dynamicTable';
import { CommonCard, ContentLoading, EmptyRecords, TitlePrimary, TitleSecondary } from '../../Utility/Utility';
import { useHelpers } from '../../Hooks/helpers';
import Timestamp from 'react-timestamp';

const Referrals = () => {

  const helper = useHelpers();

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getUserReferrals');
  
  let tableHead = [
    <>Username</>, 
    <>Referral ID</>, 
    <>Address</>, 
    <>Entries Owned</>,
    <>Total Earned</>,
    <>Registered</>
  ];

  return (
    <CommonCard>
      <div className="mb-5 flex justify-between items-center">
          <TitleSecondary variant="light-blue">
              My Referrals
          </TitleSecondary>
      </div>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(data.length > 0 && !loading) && data.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={index}>
                    <td className={classes}>
                      <helper.CopyText value={d.username} />
                    </td>
                    <td className={classes}>
                      {d.referral_id}
                    </td>
                    <td className={classes}>
                      <helper.trimAddress address={d.address} copy/>
                    </td>
                    <td className={classes}>
                      {d.entries_owned}
                    </td>
                    <td className={classes}>
                      <helper.AmountToCurrency amount={d.payments_received} />
                    </td>
                    <td className={classes}>
                      <Timestamp date={d.registered} /><br/>
                      <Timestamp date={d.registered} relative className='opacity-60' />
                    </td>
                  </tr>
                );
              },
            )}
            {loading && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
              </tr>
            }
            {((!data || data.length === 0) && !loading) && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </CommonCard>
  );
};

export default Referrals;