
const runDevAsProd = false;
const settings = {
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.1.25',
    appName: 'NewUnity',
    appDescription: 'NewUnity',
    appLink: 'https://app.newunity.io/',
    websiteLink: 'https://www.newunity.io/',

    gaID: "G-CTDVL8W4LS",
    walletConnectKey: '691dcf815510fe20382654ae84b6a8cb',
    walletConnectAllowEndpoint: settings.useDevServer?'http://localhost.3000':'https://app.newunity.io',

    apiEndpoint: settings.useDevServer?'http://localhost:6478/api/':'https://api.newunity.io/api/',
    scanUrl: settings.useDevServer?'https://mumbai.polygonscan.com/':'https://polygonscan.com/',
    
    //URLs
    support: 'https://newunity.freshdesk.com/support/tickets/new',
    payLink: 'https://pay.dex3.io/pay/',
    payoutLink: 'https://pay.dex3.io/payout/',
    
    //SOcials
    pdf_complan: 'https://drive.google.com/file/d/1WGNaEvhHHxLk7uNtJDthuXD2FrRNzL5l/preview',
    telgram: 'https://t.me/newunityio',
    youtube_complan: 'https://vimeo.com/929583546',
}

export default appConfig;