import React from 'react';
import { Users } from 'react-feather';

const UserDownlines = ({ downlines }) => {

  let tableHead = [
    <>My Unilevel Downlines</>, 
    <></>
  ];

  return (
    <div className="w-full overflow-scroll px-0 table-custom">
      <table className="w-full">
        <thead>
          <tr>
            {tableHead.map((head, key) => (
              <th key={key} className="">{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {downlines.map(
            (d, index) => {
              const classes =  "p-4";
              return (
                <tr key={index}>
                  <td className={classes}>
                    Level {d.level}
                  </td>
                  <td className='font-bold flex justify-end items-center text-blue-400'>
                    <Users className='w-4 mr-1' /> {d.count}
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserDownlines;