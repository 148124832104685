import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import Auth pages
import Login from "../Pages/auth/Login";

//Import dashboard pages
import Register from "../Pages/auth/Register";
import Splash from "../Pages/auth/Splash";
import Activities from "../Pages/dash/Activities";
import BonusPool from "../Pages/dash/BonusPool";
import Dashboard from "../Pages/dash/Dashboard";
import Entries from "../Pages/dash/Entries";
import Entry from "../Pages/dash/Entry";
import Payments from "../Pages/dash/Payments";
import Products from "../Pages/dash/Products";
import Purchase from "../Pages/dash/Purchase";
import Referrals from "../Pages/dash/Referrals";
import Topup from "../Pages/dash/Topup";
import Withdraw from "../Pages/dash/Withdraw";
import Newunitypro from "../Pages/dash/Newunitypro";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/newunitypro" />}></Route>

        <Route path="/newunitypro" element={<Newunitypro />}></Route>
        
        <Route path="/dashboard" element={<Dashboard />}></Route>

        <Route path="/purchase" element={<Purchase />}></Route>
        <Route path="/purchase/:status/:orderid" element={<Purchase />}></Route>
        
        <Route path="/withdraw" element={<Withdraw />}></Route>
        <Route path="/withdraw/:status/:orderid" element={<Withdraw />}></Route>

        <Route path="/topup" element={<Topup />}></Route>
        <Route path="/topup/:status/:orderid" element={<Topup />}></Route>

        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/referrals" element={<Referrals />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route path="/products" element={<Products />}></Route>

        {/* BOnus pool */}
        <Route path="/bonuspool" element={<BonusPool />}></Route>

        {/* Entry */}
        <Route path="/entries" element={<Entries />}></Route>
        <Route path="/entry/:id" element={<Entry />}></Route>

        {/* Authentication Routes */}
        <Route path="/splash" element={<Splash />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/login/:login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>

        {/* Referral Link */}
        <Route path="/ref/:ref" element={<Splash />}></Route>

    </Routes>
  );
}