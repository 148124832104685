import { Button, Tooltip } from "@material-tailwind/react";
import React from "react";
import { Check, Copy } from "react-feather";
import { useCopyToClipboard } from "usehooks-ts";
import { useUserAuth } from "../../Contexts/authContext";
import appConfig from "../../Utility/AppConfig";

export default function RefLink() {

  const auth = useUserAuth();

  const [value, copy] = useCopyToClipboard();
  const [copied, setCopied] = React.useState(false);

  return (
    <Tooltip content={copied ? "Copied" : "Copy"}>
      <Button
        onMouseLeave={() => setCopied(false)}
        onClick={() => {
          copy(appConfig.appLink + 'ref/' + auth.appData.user.username);
          setCopied(true);
        }}
        className="w-full justify-between flex items-center gap-x-3 px-5 py-2 lowercase bg-[#F4F4F4] shadow-none hover:shadow-none rounded-[100px]"
      >
        <p className="text-sm font-medium text-[#303030] text-left">
          <span className="block text-[#949494] text-xs">
            Invitation Link :
          </span>{
          appConfig.appLink}ref/{auth.appData.user.username}
        </p>
        <span className="bg-white rounded-lg px-3 py-2 pt-[6px] text-xs text-[#1A1D1F] font-semibold">
          {copied ? <Check size={15} /> : <Copy size={15} />}
        </span>
      </Button>
    </Tooltip>
  );
}
