import { WalletIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingTxnDialog } from "../../modals/ProcessingTxnDialog";
import { useWaitRePurchase } from "../../../Hooks/waitRePurchase";
import { useNavigate } from "react-router-dom";

const RecontributeEntries = ({ showPopup = false }) => {

  const helper = useHelpers();
  const auth = useUserAuth();
  const waitTx = useWaitRePurchase();
  const appDialog = useAppDialog();
  const processingDialog = useAppDialog();
  const callAPI = useCallAPI();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if(auth.loggedIn) {
      getCount();
    }
  }, [auth.loggedIn]);

  const getCount = async () => {
    try {

      const res = await callAPI('user/getrepurchaseentriescount');
      setCount(res);

      if(res*1 > 0 && showPopup) {
        showPopup.open();
      }

    } catch (error) {
      console.log(error);
    }
  }

  const reContribute = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/repurchaseentries');
      await waitTx.wait(res.hash, processingDialog, appDialog);
      await auth.bootApp();
      await getCount();
      showPopup && showPopup.close();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <>
      <div className="bg-white rounded-10 shadow-md p-5 mt-3">
        <div className="flex justify-between items-center gap-2">
          <div className="">
            <h4 className="text-blue-400 text-4xl font-bold"><helper.AmountToCurrency amount={auth?.appData.user.repurchaseBalance} /></h4>
            <p className="text-gray-600 text-sm">Re-Contribution Wallet</p>
            <Button disabled={loading || count == 0} loading={loading} onClick={reContribute} className="bg-blue-400 mt-3">Contribute {count} Entries</Button>
          </div>
          <WalletIcon className="w-[70px] text-gray-300" />
        </div>
        <p className="mt-4 text-xs text-gray-700 leading-4">Everytime you claim donations, 10% of amount claimed will be sent to re-contribution wallet which you can use to contribute new entries every time the wallet collects $40.</p>
        <h4 className="text-gray-700 text-lg font-bold mt-4">Low balance? Topup now</h4>
        <Button onClick={() => navigate('/topup')} className="bg-green-400 mt-2">Topup Now</Button>
      </div>
      <ProcessingTxnDialog hook={processingDialog} />
      <AppDialog hook={appDialog} />
    </>
  );
};

export default RecontributeEntries;
