import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast";
import * as Yup from 'yup';
import { useCallAPI } from "../../Hooks/callAPI";
import { FormInput } from "../../Utility/Inputs";

const UpdateUsername = ({ auth }) => {

  const callAPI = useCallAPI();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});

  const defaultValues = {
      username: auth?.appData?.user?.username
  }

  const validationSchema = Yup.object().shape({
      username: Yup.string()
          .required('Please enter a username')
          .min(5, "Please enter atleast 5 characters")
          .matches(/^[a-zA-Z0-9]+$/, 'Alphanumeric only')
          .max(20, "Please keep it short to 20 characters"),
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ defaultValues: defaultValues, mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {
    try {

      setLoading(true);
      setFormError({});

      const formData = getValues();
      const res = await callAPI("user/updateUsername", formData);
      await auth.bootApp();
      toast.success('Username updated successfully');
      setLoading(false);

    } catch (error) {
      if(error.code && error.code === 403) { //Handle form error
          setFormError(error.errData);
      }
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white rounded-10 shadow-md p-5">
        <div className="">
          <FormInput name='username' label="Update Username" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty || loading} className="bg-[#04D88C] mt-3">Update Now</Button>
          <p className="text-xs mt-4">Attention: Changing username will also change your invitation link.</p>
        </div>
      </div>
    </form>
    </>

  );
};

export default UpdateUsername;