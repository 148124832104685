import { useEffect, useState } from "react";
import WithdrawBlock from "../../Components/dash/withdraw/WithdrawBlock";
import WithdrawHistory from "../../Components/dash/withdraw/WithdrawHistory";
import { useUserAuth } from "../../Contexts/authContext";

const Withdraw = () => {

  const auth = useUserAuth();

  const [historyKey, setHistoryKey] = useState(1);

  useEffect(() => {
    setHistoryKey(historyKey+1);
  }, [auth]);

  return (
    <>
      <WithdrawBlock />
      <WithdrawHistory key={historyKey} />
    </>
  );
};
export default Withdraw;