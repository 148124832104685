import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverContent, PopoverHandler } from "@material-tailwind/react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import { AlertTriangle, X } from "react-feather";

export const FormInput = ({ children, ...props }) => {
  return (
    <div>
      {props.label && <label htmlFor="" className="block text-gray-700 font-normal text-sm mb-3">{props.label}</label>}
      <input {...props} className="shadow-md w-full min-w-[200px] rounded-10 h-12 bg-[#f7f7f7] disabled:bg-[#cccccc] disabled:cursor-not-allowed disabled:opacity-70 px-4 text-gray-800 outline-none  border border-[#aaaaaa] transition text-sm duration-200 focus:border-blue-400 " {...props.reg(props.name)} />
      {(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? 
        <>
          {props.errors[props.name]?.message && <span className="flex justify-end items-center text-right text-[#FF2020] text-xs font-bold mt-2"><AlertTriangle width={12} className="mr-1"/> {props.errors[props.name]?.message}</span>}
          {props.formerror[props.name] && <span className="flex justify-end items-center text-right text-[#FF2020] text-xs font-bold mt-2"><AlertTriangle width={12} className="mr-1"/> {props.formerror[props.name].msg}</span>}
        </>
        :
        <>{props.helper && <span className="flex justify-end text-right text-gray-500 text-xs mt-2"><InformationCircleIcon width={12} className="mr-1"/> {props.helper}</span>}</>
      }
    </div>
  );
};

export const FormSelect = ({ children, ...props }) => {
  return (
    <div>
      {props.label && <label htmlFor="" className="block text-gray-700 font-normal text-sm mb-3">{props.label}</label>}
      {props.options && props.options.length > 0 && 
        <select {...props} className="shadow-md w-full min-w-[200px] rounded-10 h-12 bg-[#f7f7f7] disabled:bg-[#cccccc] disabled:cursor-not-allowed disabled:opacity-70 px-4 text-gray-800 outline-none  border border-[#aaaaaa] transition text-sm duration-200 focus:border-blue-400 " {...props.reg(props.name)}>
          {props.options.map((e, i) => {
              return (
                <option value={e.value} key={i}>{e.label}</option>
              );
          })}
        </select>
      }
      {(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? 
        <>
          {props.errors[props.name]?.message && <span className="flex justify-end items-center text-right text-[#FF2020] text-xs font-bold mt-2"><AlertTriangle width={12} className="mr-1"/> {props.errors[props.name]?.message}</span>}
          {props.formerror[props.name] && <span className="flex justify-end items-center text-right text-[#FF2020] text-xs font-bold mt-2"><AlertTriangle width={12} className="mr-1"/> {props.formerror[props.name].msg}</span>}
        </>
        :
        <>{props.helper && <span className="flex justify-end text-right text-gray-500 text-xs mt-2"><InformationCircleIcon width={12} className="mr-1"/> {props.helper}</span>}</>
      }
    </div>
  );
};

export const FormDate = ({ children, ...props }) => {
  return (
    <div>
      {props.label && <label htmlFor="" className="block text-gray-700 font-normal text-sm mb-3">{props.label}</label>}
      <div className="flex items-center relative">
        <Popover placement="bottom">
          <PopoverHandler>
            <input {...props} onChange={() => null} value={props.date ? format(props.date, "PPP") : ""} className="shadow-md w-full min-w-[200px] rounded-10 h-12 bg-[#f7f7f7] disabled:bg-[#cccccc] disabled:cursor-not-allowed disabled:opacity-70 px-4 text-gray-800 outline-none  border border-[#aaaaaa] transition text-sm duration-200 focus:border-blue-400 " />
          </PopoverHandler>
          <PopoverContent>
            <DayPicker
              mode="single"
              selected={props.date}
              onSelect={props.setDate}
              showOutsideDays
              className="border-0"
              
            />
          </PopoverContent>
        </Popover>
        <X className="absolute right-2 text-red-600" onClick={() => props.setDate('')} />
      </div>
      {props.errors[props.name]?.message ? 
        <>
          {props.errors[props.name]?.message && <span className="flex justify-end items-center text-right text-[#FF2020] text-xs font-bold mt-2"><AlertTriangle width={12} className="mr-1"/> {props.errors[props.name]?.message}</span>}
        </>
        :
        <>{props.helper && <span className="flex justify-end text-right text-gray-500 text-xs mt-2"><InformationCircleIcon width={12} className="mr-1"/> {props.helper}</span>}</>
      }
    </div>
  );
};