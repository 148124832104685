import { Dialog, DialogBody } from '@material-tailwind/react';
import { PuffLoader } from 'react-spinners';
import { useHelpers } from '../../Hooks/helpers';

export const ProcessingTxnDialog = ({ hook }) => {

    const helper = useHelpers();

    return (
        <Dialog size='sm' open={hook.isOpen} dismiss={hook.close} handler={null}>
            <DialogBody className='p-7 text-center'>
                <PuffLoader
                    color='#52B86E'
                    loading={true}
                    size={40}
                    aria-label="Loading"
                    data-testid="loader"
                    className='mx-auto'
                />
                <h4 className="text-xl font-bold leading-6 mt-4 text-gray-900">{hook.data.header || 'Processing Transaction'}</h4>
                <p className="text-sm mt-2 font-normal text-gray-600">{hook.data.description || <>Please wait for the transaction to complete processing. <br/>Estimated wait time : 0-2 minutes.</>}</p>
                {hook.data.hash && <span className='bg-blue-400 mt-4 inline-block text-white px-4 py-1 text-sm font-bold rounded-md'><helper.trimAddress address={hook.data.hash} copy scanLink={'tx/' + hook.data.hash} /></span>}
            </DialogBody>
        </Dialog>
    )
}