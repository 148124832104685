import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from "@material-tailwind/react";
import { default as React, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import * as yup from 'yup';
import IconLogin from "../../Assets/login.png";
import { AppDialog } from '../../Components/modals/AppDialog';
import { ProcessingTxnDialog } from '../../Components/modals/ProcessingTxnDialog';
import { useAppDialog } from '../../Hooks/appDialog';
import { useCallAPI } from '../../Hooks/callAPI';
import { useWaitRegister } from '../../Hooks/waitRegister';
import { useWrite } from '../../Hooks/writeContract';
import { AppTitle } from '../../Utility/Headings';
import toast from 'react-hot-toast';

const Register = () => {

    const callAPI = useCallAPI();
    const account = useAccount();
    const writeContract = useWrite();
    const waitTxn = useWaitRegister();
    const pendingTxDialog = useAppDialog();
    const appDialog = useAppDialog();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState({});
    const [connectAddr, setConnectAddr] = useState(account.address);

    useEffect(() => {
      setConnectAddr(account.address);
    }, [account.address]);

    const FormSchema = yup.object().shape({
      referrerUsername: yup.string().max(19).label("Referrer Username").required("Referrer username is required").matches(/^[a-zA-Z0-9]+$/, 'Alphanumeric only')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    useEffect(() => {
        if(localStorage.getItem("referrerUsername") !== null && localStorage.getItem("referrerUsername") !== undefined) {
            setValue("referrerUsername", localStorage.getItem("referrerUsername"));
        }
    }, []);
    
    const registerNow = async () => {
      try {

        setLoading(true);
        setFormError({});

        const formData = getValues();
        formData.address = connectAddr;
        const res = await callAPI("auth/getRegisterNonce", formData);
        const signature = await writeContract.SignMessage(res.nonce, connectAddr);
        
        const hash = await callAPI("auth/completeRegistration", { hash: signature, address: connectAddr });
        await waitTxn.wait(hash, pendingTxDialog, appDialog);

        navigate('/login/yes');
        setLoading(false);

      } catch (error) {
        if(error.code && error.code === 403) { //Handle form error
            setFormError(error.errData);
        }
        console.log(error);
        setLoading(false);
      }
    }

    return (
      <>
        <div className="space-y-6 xl:space-y-14">
          <div className="border-dashed border-2 border-t-0 border-l-0 border-r-0 border-[#E5E5E5] pb-10 xl:pb-14">
            {!account.isConnected ?
              <AppTitle>
                <span className="inline-block highlight-title">1. Connect</span>&nbsp; your wallet
              </AppTitle>
            :
              <AppTitle>
                <div className='flex'><span className="inline-block highlight-title">1. Wallet</span>&nbsp;Connected <CheckBadgeIcon className='w-[34px] text-blue-500 ml-2' /></div>
              </AppTitle>
            }
            <div className="mt-5 xl:mt-8">
              <w3m-button />
              <p className='text-xs mt-2 text-gray-700'>Click ☝️ to show wallet details</p>
            </div>
          </div>
          <div className="border-dashed border-2 border-t-0 border-l-0 border-r-0 border-[#E5E5E5] pb-10 xl:pb-14">
            <AppTitle>
              <span className="inline-block highlight-title">2. Enter</span>&nbsp;Referrer Username
            </AppTitle>
            <div className="relative">
              <input
                  type="text"
                  placeholder="Referrer Username"
                  {...register('referrerUsername')}
                  className="mt-4 border-[2px] mb-2 border-teal-300 rounded-2xl w-full h-[55px] bg-transparent px-4 focus:outline-none placeholder:text-lightblue"
              />
              {formError?.address && <p className="text-red-400 text-xs my-2">{formError?.address.msg}</p>}
              {errors.referrerUsername?.message && <p className="text-red-400 text-xs my-2">{errors.referrerUsername?.message}</p>}
              {formError?.referrerUsername && <p className="text-red-400 text-xs my-2">{formError?.referrerUsername.msg}</p>}
            </div>
          </div>
          <div>
            <AppTitle>
              <span className="inline-block highlight-title">
                3. Sign
              </span>&nbsp;Wallet
            </AppTitle>
            <p className="text-gray text-lg leading-snug">
              Sign a nonce request from your wallet to proceed. <br/>You will not spend any BNB to register.
            </p>
            <div className="mt-6 flex items-center space-x-5">
              <Button
                loading={loading}
                disabled={account.isConnected !== true || loading}
                onClick={registerNow}
                variant="gradient"
                className="bg-[#04D88C] flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
              >
                Register <img className="w-6 h-6" src={IconLogin} alt="i" />
              </Button>
            </div>
          </div>
        </div>
        <ProcessingTxnDialog hook={pendingTxDialog} />
        <AppDialog hook={appDialog} />
      </>
    );
};

export default Register;
