import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card } from '@material-tailwind/react';
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import purchaseBg from '../../../Assets/purchasebg.jpg';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingDepositModal, usePendingDeposit } from '../../../Hooks/pendingDeposit';
import { FormInput } from '../../../Utility/Inputs';
import { TitlePrimary } from '../../../Utility/Utility';
import BalanceBox from './BalanceBox';

const TopupBlock = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const params = useParams();
  const pendingDeposit = usePendingDeposit();
  
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState({});
 
  useEffect(() => {
    if(auth.loggedIn) {
      getResults();
    }
  }, [auth.loggedIn]);

  const getResults = async () => {
    try {

      // Check here if any params is present
      if(params.orderid) {
        if(params.status === 'success') {
          await pendingDeposit.open({ orderId: params.orderid, successDesc: 'Your topup amount is successfully credited to your contribution balance'});
        } else if(params.status === 'cancel') {
          toast.error('Topup cancelled by user.');
        }
      }

    } catch (error) {
      console.log(error);
    }
  }
  
  const deposit = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/topup', { amount: getValues('amount') });
      window.open(res.payLink, '_self');

    } catch (error) {
      if(error.code && error.code === 403) { //Handle form error
        setFormError(error.errData);
      }
      setLoading(false);
      console.log(error);
    }
  }

  const FormSchema = yup.object().shape({
    amount: yup.number().label('Amount must be a number')
      .required('Amount is required')
      .test('is-decimal', 'Amount must be a decimal up to 2 places', (value) => {
        if (value) {
          const decimalPlaces = (value.toString().split('.')[1] || []).length;
          return decimalPlaces <= 2;
        }
        return true;
      })
  });

  const {
      getValues,
      register,
      setValue,
      formState: { errors, isValid, isDirty }
  } = useForm({ defaultValues: { amount: 100, topupGas: false }, mode: 'onChange', resolver: yupResolver(FormSchema) })

  return (
    <div className='px-4 mb-4'>
      <div className="mb-5">
        <TitlePrimary>Topup Re-Contribution Wallet</TitlePrimary>
      </div>
      <BalanceBox />
      <div className="rounded-lg mb-6 flex justify-center p-8" style={{ backgroundImage: `url(${purchaseBg}`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <div className="bg-[#FCFCFC]/95 rounded-2xl px-4 py-5 md:p-6 shadow-md max-w-[500px] w-full text-center">
            <FormInput type='number' name='amount' label="Enter amount to add to your wallet" helper={'Enter amount'} reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
            <div className="flex justify-center mt-5">
              <Button
                loading={loading}
                disabled={loading || !isValid}
                onClick={deposit}
                variant="gradient"
                color="green"
                className="bg-[#04D88C] flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
              >
                Proceed Now
              </Button>
            </div>
        </div>
      </div>
      <PendingDepositModal hook={pendingDeposit} />
    </div>
  );
};
export default TopupBlock;