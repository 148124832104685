import React from 'react';
import EntryBox from '../../Components/dash/entries/EntryBox';
import useDynamicTable, { DynamicPagination } from '../../Hooks/dynamicTable';
import { ContentLoading, EmptyRecords, TitlePrimary } from '../../Utility/Utility';

const Entries = () => {

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('user/getUserEntries');

  return (
    <>
      <TitlePrimary>Entries</TitlePrimary>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6">
        {(data.length > 0 && !loading) && data.map((d, index) => {
          return (
            <EntryBox key={index} entry={d} />
          );
        })}
      </div>
      {loading && 
        <div className='flex justify-center pt-3'><ContentLoading /></div>
      }
      {((!data || data.length === 0) && !loading) && 
        <EmptyRecords title='No entries found' description='Purchased entries will be shown here' />
      }
      <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </>
  );
};

export default Entries;