import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import AuthLayout from "../Components/layout/Auth";
import DashboardLayout from "../Components/layout/Dashboard";
import InvalidAddress from '../Components/layout/InvalidAddress';
import { useUserAuth } from '../Contexts/authContext';
import LayoutLoading from "./LayoutLoading";
import appConfig from '../Utility/AppConfig';

export default function Layout() { 

    const auth = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const account = useAccount();

    //Check for authentication and redirect to login if not logged in
    useEffect(() => {
        if(auth.appBooted === true && auth.loggedIn !== true && (!location.pathname.includes('/login') && !location.pathname.includes('/register') && !location.pathname.includes('/splash') && !location.pathname.includes('/ref'))) {
            navigate('/splash');
            return;
        }
        if(auth.appBooted === true && auth.loggedIn === true && (location.pathname.includes('/login') || location.pathname.includes('/register') || location.pathname.includes('/splash') || location.pathname.includes('/ref'))) {
            navigate('/newunitypro');
            return;
        }
    }, [auth]);
      
    return (
        <div className='bg-gray-900'>
            {auth.loggedIn && account.isConnected && (auth?.appData.user.address.toLowerCase() !== account.address.toLowerCase()) && <InvalidAddress />}
            {auth.appBooted && auth.loggedIn === false && <AuthLayout />}
            {auth.appBooted && auth.loggedIn === true && <DashboardLayout />}
            {!auth?.appBooted && <LayoutLoading />}
        </div>
    );
}