import { Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { MinusCircle, PlusCircle } from "react-feather";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import IconLogin from "../../../Assets/plus.png";
import purchaseBg from '../../../Assets/purchasebg.jpg';
import { useUserAuth } from "../../../Contexts/authContext";
import { useAppDialog } from "../../../Hooks/appDialog";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { useWaitPurchase } from "../../../Hooks/waitPurchase";
import appConfig from "../../../Utility/AppConfig";
import { ContentLoading, TitlePrimary } from "../../../Utility/Utility";
import { AppDialog } from "../../modals/AppDialog";
import { ProcessingDex3Dialog } from "../../modals/ProcessingDex3Dialog";

const PurchaseBlock = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const helper = useHelpers();
  const waitTx = useWaitPurchase();
  const appDialog = useAppDialog();
  const pendingTxDialog = useAppDialog();
  const params = useParams();
  
  const [settings, setSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
 
  useEffect(() => {
    if(auth.loggedIn) {
      getPurchaseSettings();
    }
  }, [auth.loggedIn]);

  const getPurchaseSettings = async () => {
    try {
      const res = await callAPI('user/getpurchasesettings');
      setSettings(res);

      // Check here if any params is present
      if(params.orderid) {
        if(params.status === 'success') {
          const paymentData = await callAPI('user/checkpurchase/' + params.orderid);
          await waitTx.wait(params.orderid, () => window.open(appConfig.payLink + paymentData.payment_id, '_self'), pendingTxDialog, appDialog);
          await auth.bootApp();
        } else if(params.status === 'cancel') {
          toast.error('Contribution Cancelled by user.');
        }
      }

    } catch (error) {
      console.log(error);
    }
  }
  
  const purchase = async () => {
    try {
      setLoading(true);
      const res = await callAPI('user/purchaseentries', { count: count });
      window.open(res.payLink, '_self');
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const counter = (add = true) => {
    if(add) {
      if(count >= settings.maxEntries*1) return;
      setCount(count+1);
    } else {
      if(count <= 1) return;
      setCount(count-1);
    }
  }

  return (
    <>
      <div className="mb-5">
        <TitlePrimary>Contribute Entries</TitlePrimary>
      </div>
      <div className="rounded-lg mb-6 flex justify-center p-8" style={{ backgroundImage: `url(${purchaseBg}`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        {settings ?
          <div className="bg-[#FCFCFC]/95 rounded-2xl px-4 py-5 md:p-6 shadow-md max-w-[500px] w-full text-center">
            <div className="font-semibold text-lg">
              Choose entries to contribute
            </div>
            <div className="flex gap-3 items-center justify-center my-10">
              <MinusCircle className="text-red-500 cursor-pointer" onClick={() => counter(false)} />
              <Button color="gray" variant="outlined" className="">{count}</Button>
              <PlusCircle className="text-blue-500 cursor-pointer" onClick={() => counter(true)} />
            </div>
            <div className="flex justify-center gap-8">
              <div className="">
                <h4 className="text-4xl font-bold text-blue-400"><helper.AmountToCurrency amount={settings.cost/100} decimals={2} /></h4>
                <p>Price per entry</p>
              </div>
              {settings.maxEntries*1 > 1 &&
                <div className="border-dashed border-2 border-t-0 border-b-0 border-r-0 border-[#E5E5E5] pl-8">
                  <h4 className="text-4xl font-bold text-green-400"><helper.AmountToCurrency amount={settings.cost*count/100} decimals={0} /></h4>
                  <p>Total cost</p>
                </div>
              }
            </div>
            <div className="flex justify-center mb-3 mt-8">
              <Button
                loading={loading}
                disabled={loading}
                onClick={purchase}
                variant="gradient"
                color="green"
                className="bg-[#04D88C] flex items-center gap-3 font-semibold font-sans text-lg rounded-full capitalize"
              >
                Contribute Now <img className="w-6 h-6" src={IconLogin} alt="i" />
              </Button>
            </div>
          </div>
        :
          <ContentLoading />
        }
      </div>
      <ProcessingDex3Dialog hook={pendingTxDialog} />
      <AppDialog hook={appDialog} />
    </>
  );
};
export default PurchaseBlock;